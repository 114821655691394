<template>
    <b-sidebar id="sidebar-right" right shadow bg-variant="white" backdrop>
        <template #default="{ hide }">
            <b-card title="Vendor">
                <b-form>
                    <b-row>
                        <b-col md="12">
                            <b-form-group label="Business Name">
                                <b-form-input v-model="businessName"
                                    placeholder="Please Enter Business Name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Bussiness Address">
                                <b-form-input v-model="bussinessAddresss"
                                    placeholder="Please Enter Bussiness Address"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Contact Person Name">
                                <b-form-input v-model="contactPersonName"
                                    placeholder="Please Enter Contact Person Name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Mobile No">
                                <b-form-input v-model="mobileNo" placeholder="Please Enter Mobile No"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Email Id">
                                <b-form-input v-model="emailId" placeholder="Please Enter Email Id"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Account No">
                                <b-form-input v-model="accountNo" placeholder="Please Enter Account No"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Bank Details">
                                <b-form-input v-model="backDetials"
                                    placeholder="Please Enter Bank Details"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Gst No">
                                <b-form-input v-model="gstNo" placeholder="Please Enter Gst No"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-checkbox class="mt-2" v-model="isActive">Is Active</b-form-checkbox>
                        </b-col>
                        <b-col md="12" class="text-center">
                            <b-button block variant="primary" class="mt-2 mr-2" v-on:click="addUpdateVendor" @click="hide">{{
                                addUpdateButton
                            }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </template>
    </b-sidebar>
</template>
  
<script>
import {
    BAvatar, BCard,BSidebar, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import vSelect from 'vue-select'

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,BSidebar,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton, BFormCheckbox, vSelect
    },
    data() {
        return {
            addUpdateButton: 'Add',
            accountNo: '',
            backDetials: '',
            businessName: '',
            bussinessAddresss: '',
            contactPersonName: '',
            emailId: '',
            gstNo: '',
            isActive: false,
            mobileNo: '',
            id: 0,
        }
    },
    props: {
        getVendors: Function
    },
    methods: {

        addUpdateVendor() {
            let self = this;
            if (!(self.businessName == '')) {
                var axios = require('axios');
                var data = JSON.stringify({
                    "accountNo": self.accountNo,
                    "backDetials": self.backDetials,
                    "businessName": self.businessName,
                    "bussinessAddresss": self.bussinessAddresss,
                    "contactPersonName": self.contactPersonName,
                    "emailId": self.emailId,
                    "gstNo": self.gstNo,
                    "isActive": self.isActive,
                    "mobileNo": self.mobileNo
                });
                axios(api.getApi('Post', '/finance/vendors', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Vendor Added Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                        self.getVendors();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Business Name.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },


    },
    mounted() {

    },
    computed: {

    },
    created() {
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>